<script setup lang="ts">
import { useBotStore } from '@/stores/ftbotwrapper';

const botStore = useBotStore();
</script>

<template>
  <footer class="d-md-none">
    <!-- Only visible on xs (phone) viewport! -->
    <hr class="my-0" />
    <div class="d-flex flex-align-center justify-content-between px-2">
      <RouterLink
        v-if="!botStore.canRunBacktest"
        class="nav-link navbar-nav align-items-center"
        to="/trade"
      >
        <i-mdi-bank height="24" width="24" />
        交易
      </RouterLink>
      <RouterLink
        v-if="!botStore.canRunBacktest"
        class="nav-link navbar-nav align-items-center"
        to="/trade_history"
      >
        <i-mdi-reorder-horizontal height="24" width="24" />
        历史
      </RouterLink>
      <RouterLink
        v-if="!botStore.canRunBacktest"
        class="nav-link navbar-nav align-items-center"
        to="/dashboard"
      >
        <i-mdi-view-dashboard-outline height="24" width="24" />
        Dashboard
      </RouterLink>
      <RouterLink
        v-if="!botStore.canRunBacktest"
        class="nav-link navbar-nav align-items-center"
        to="/pairlist"
      >
        <i-mdi-view-list height="24" width="24" />
        交易对
      </RouterLink>
      <RouterLink
        v-if="!botStore.canRunBacktest"
        class="nav-link navbar-nav align-items-center"
        to="/balance"
      >
        <i-mdi-bank height="24" width="24" />
        资产
      </RouterLink>

    </div>
  </footer>
</template>

<style lang="scss" scoped>
[data-theme='dark'] {
  .router-link-active,
  .nav-link:active {
    color: white !important;
  }
}
</style>
